import { styled } from '@mui/material/styles';
import { CircularProgress, LinearProgress } from '@mui/material';

const Spinner = styled(LinearProgress)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 9999,
});

export default Spinner;
