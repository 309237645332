import { Box, SxProps, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { StyledTableCell } from 'app/pages/RestaurantSettings/_components/StyledTableCell';

interface OwnProps {
  headRowsToRender: ReactNode[];
  rowsToRender: ReactNode[] | ReactNode;
  sx?: SxProps;
  firstCellEmpty?: boolean;
}

export const CustomTable = ({ headRowsToRender, rowsToRender, sx, firstCellEmpty }: OwnProps) => {
  const theme = useTheme();

  return (
    <TableContainer
      component={Box}
      sx={{
        borderRadius: theme.borderRadius.default,
        overflowX: theme.breakpoints.up('xs') ? 'unset' : 'auto',
        ...sx,
      }}
    >
      <Table sx={{ borderRadius: (theme) => theme.spacing(0.625), overflow: 'hidden' }}>
        <TableHead
          sx={{
            backgroundColor: 'zircon',
            borderBottom: '1px solid',
            borderColor: 'brandWhite',
          }}
        >
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {!!firstCellEmpty && <StyledTableCell />}

            {headRowsToRender}
          </TableRow>
        </TableHead>
        <TableBody>{rowsToRender}</TableBody>
      </Table>
    </TableContainer>
  );
};
