import paths from 'app/router/paths';
import { lazy } from 'react';
import { getNotificationEmailSettingsCall } from 'api/app/restaurant/settings/notification';

const EmailSettings = lazy(
  () => import('app/pages/RestaurantSettings/Notifications/EmailSettings'),
);

const notifications = [
  {
    path: paths.settings.notifications.emailSettings,
    loader: async () => await getNotificationEmailSettingsCall(),
    element: <EmailSettings />,
  },
];

export default notifications;
