import paths from '../../paths';
import React, { lazy } from 'react';
import { getUsersCall } from 'api/app/account/user';
import { getOwnerCall } from 'api/app/account/owner';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';
import GenericError from 'app/components/ErrorDisplay/GenericError';

const BasicInformationPage = lazy(() => import('app/pages/RestaurantSettings/BasicInformation'));
const TimeAndLanguagePage = lazy(() => import('app/pages/RestaurantSettings/TimeAndLanguage'));
const OwnerPage = lazy(() => import('app/pages/RestaurantSettings/Owner'));
const UserAccounts = lazy(() => import('app/pages/RestaurantSettings/UserAccounts'));
const TimeManagement = lazy(() => import('app/pages/RestaurantSettings/TimeManagement'));

const accountSetup = [
  {
    path: paths.settings.accountSetup.restaurantInformation,
    element: (
      <Guard
        access={Access.ManageRestaurantInfo}
        component={<BasicInformationPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.accountSetup.timeAndLanguage,
    element: (
      <Guard
        access={Access.ManageTimeAndLanguage}
        component={<TimeAndLanguagePage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.accountSetup.ownerAccount,
    loader: async () => await getOwnerCall(),
    element: (
      <Guard
        access={Access.ManageOwnerAccount}
        component={<OwnerPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.accountSetup.users,
    loader: async () => await getUsersCall(),
    element: (
      <Guard
        access={Access.ManageUsers}
        component={<UserAccounts />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
];

export default accountSetup;
